p {
    font-size: 1rem;
    line-height: 1.5rem;
}

ul {
    padding-left: 0 !important;
}

ul > li {
    font-size: 1rem;
    line-height: 1.5rem;
}

ul > li:before {
    content: "-";
    text-indent: -5px;
    padding-right: 5px;
}

button {
    @apply  transition ease-in-out duration-150 focus:outline-none focus:ring focus:ring-black;
    outline: 2px solid transparent !important;
}
