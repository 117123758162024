@font-face {
    font-family: 'Abril Fatface';
    src: url('../fonts/AbrilFatface-Regular.woff2') format('woff2'),
    url('../fonts/AbrilFatface-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-ExtraBold.woff2') format('woff2'),
    url('../fonts/Muli-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-BoldItalic.woff2') format('woff2'),
    url('../fonts/Muli-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-BlackItalic.woff2') format('woff2'),
    url('../fonts/Muli-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Bold.woff2') format('woff2'),
    url('../fonts/Muli-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Bold.woff2') format('woff2'),
    url('../fonts/Muli-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-ExtraBold.woff2') format('woff2'),
    url('../fonts/Muli-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-BlackItalic.woff2') format('woff2'),
    url('../fonts/Muli-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-ExtraBoldItalic.woff2') format('woff2'),
    url('../fonts/Muli-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Black.woff2') format('woff2'),
    url('../fonts/Muli-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Black.woff2') format('woff2'),
    url('../fonts/Muli-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-BoldItalic.woff2') format('woff2'),
    url('../fonts/Muli-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Medium.woff2') format('woff2'),
    url('../fonts/Muli-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-MediumItalic.woff2') format('woff2'),
    url('../fonts/Muli-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Light.woff2') format('woff2'),
    url('../fonts/Muli-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Italic.woff2') format('woff2'),
    url('../fonts/Muli-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-LightItalic.woff2') format('woff2'),
    url('../fonts/Muli-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-ExtraLightItalic.woff2') format('woff2'),
    url('../fonts/Muli-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-MediumItalic.woff2') format('woff2'),
    url('../fonts/Muli-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-ExtraLightItalic.woff2') format('woff2'),
    url('../fonts/Muli-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Light.woff2') format('woff2'),
    url('../fonts/Muli-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-LightItalic.woff2') format('woff2'),
    url('../fonts/Muli-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-ExtraLight.woff2') format('woff2'),
    url('../fonts/Muli-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Medium.woff2') format('woff2'),
    url('../fonts/Muli-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-ExtraBoldItalic.woff2') format('woff2'),
    url('../fonts/Muli-ExtraBoldItalic.woff') format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Italic.woff2') format('woff2'),
    url('../fonts/Muli-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-ExtraLight.woff2') format('woff2'),
    url('../fonts/Muli-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Regular.woff2') format('woff2'),
    url('../fonts/Muli-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-Regular.woff2') format('woff2'),
    url('../fonts/Muli-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-SemiBold.woff2') format('woff2'),
    url('../fonts/Muli-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-SemiBold.woff2') format('woff2'),
    url('../fonts/Muli-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/Muli-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Muli';
    src: url('../fonts/Muli-SemiBoldItalic.woff2') format('woff2'),
    url('../fonts/Muli-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

