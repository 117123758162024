.absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form-error a {
    text-decoration: underline;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.animate-scroll {
    animation: scroll 40s linear infinite;
}

input.appearance-none::-webkit-outer-spin-button,
input.appearance-none::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input.appearance-none[type=number] {
    -moz-appearance: textfield;
}

/*Tablet*/
@media (min-width: 640px) {
    .tablet\:absolute-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
