.ReactModal__Body--open {
    overflow: hidden !important;
}

.ReactModal__Overlay {
    background-color: rgba(29, 27, 27, 0.9) !important;
    opacity: 0;
    animation: fade-in 0.25s ease forwards;
    z-index: 100;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.ReactModal__Content {
    width: 100% !important;
    max-width: 100%;
    height: 100% !important;
    position: relative !important;
    padding: 0 !important;
    border-radius: 0 !important;
    opacity: 0;
    animation: fade-in 0.25s ease forwards;
    border: 0 !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    background-color: white;
    overflow: auto;
}

#product-images-modal {
    background: transparent !important;
    display: flex !important;
    align-items: center !important;
}


#mobile-menu-modal {
    width: 100% !important;
    max-width: 100% !important;
    min-height: 466px !important;
    height: auto !important;
}

#small-modal {
    max-width: 475px !important;
    height: auto !important;
}

#how-it-works-modal {
    max-width: 90% !important;
    height: auto !important;
}

#cancel-order-modal {
    max-width: 475px !important;
    height: 100% !important;
}

#bill-summary-modal {
    max-width: 100% !important;
    height: 100% !important;
}

@screen tablet {
    #bill-summary-modal {
        max-width: 420px !important;
    }
}

@screen laptop {
    .ReactModal__Content {
        max-width: 1302px !important;
        width: calc(100% - 64px) !important;
        height: 90% !important;
    }
    #how-it-works-modal {
        max-width: 1302px !important;
        width: calc(100% - 64px) !important;
        height: auto !important;
    }

    #product-images-modal {
        max-width: 100% !important;
        width: 100% !important;
        background: transparent !important;
        height: 100% !important;
        display: flex !important;
        align-items: center !important;
    }

    #cancel-order-modal {
        height: auto !important;
    }

    .room-size-modal {
        max-width: 918px !important;
        height: auto !important;
        max-height: 90%;
    }
}
