@import "tailwindcss/base";

@import "fonts.css";
@import "base.css";
@import "form.css";
@import "custom.css";
@import "modal.css";
@import "animations.css";
@import "tooltip.css";

@import "tailwindcss/utilities";
